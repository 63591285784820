import { defineStore } from "pinia";
import type { Agent, ChatMessage } from "~/types/models";
import { CHAT_ROLE } from "~/types/models";

export const useChatStore = defineStore("chat", {
  state: () => ({
    agents: [] as Agent[],
    promptMessage: "",
    messages: [] as ChatMessage[],
    loading: false,
    _session: null as string | null,
  }),
  getters: {
    agentsNames: (state) => {
      return state.agents.map((a) => a.name);
    },
    activeAgents(): Agent[] {
      return this.agents.filter((a) => this.agentsNames.includes(a.name));
    },
    session: (state) => {
      if (state._session === null) {
        if (localStorage.getItem('abby-session') === null) {
          localStorage.setItem('abby-session', crypto.randomUUID())
        }
        state._session = localStorage.getItem('abby-session')
      }
      return state._session
    }
    // currentAgent: (state) => state.activeAgents.find((a) => a.name === state.currentAgentName),
  },
  actions: {
    async loadAgents() {
      this.agents = await $fetch("/api/agents")
    },
    getCurrentAgent(): Agent {
      const route = useRoute();
      return this.getAgent(route.query.agentName as string);
    },
    getAgent(name: string): Agent {
      const agent = this.agents.find((a) => a.name === name);
      return agent
        ? agent
        : {
          name: "undef",
          welcome_message: "welcome",
        };
    },
    async sendMessage() {
      if (this.promptMessage === "") return;
      const messageToSend = `${this.promptMessage}`;
      this.promptMessage = "";
      this.messages.push({
        role: CHAT_ROLE.User,
        message: messageToSend,
      });
      this.loading = true;
      const result = await $fetch('/api/chat', {
        method: 'POST',
        body: { text: messageToSend, session_id: this.session, agent_name: this.getCurrentAgent().name, language_code: "it" }
      })
      this.loading = false;
      this.messages.push({
        role: CHAT_ROLE.Abby,
        message: result,
      });
      // console.log({ messageToSend, result })
    },
  },
});
