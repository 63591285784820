export class CHAT_ROLE {
  static readonly Abby = "Abby";
  static readonly User = "User";
}

export type ChatMessage = {
  role: CHAT_ROLE;
  message: string;
};

export type Agent = {
  name: string;
  welcome_message: string;
  examples?: string[];
};

export type ReqAgent = {
  agents: Agent[]
}